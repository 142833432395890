import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import UnixTimestampConverter from './UnixTimestampConverter';

function App() {
    return (
        <Router>
            <div className="App">
                {/* Navigation bar to link to each tool page */}
                <nav>
                    <ul>
                        <li><Link to="/">Unix Timestamp Converter</Link></li>
                    </ul>
                </nav>
                {/* Routes for each tool */}
                <Routes>
                    <Route path="/" element={<UnixTimestampConverter />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;

