import React, { useState } from 'react';

function UnixTimestampConverter() {
    const [timestamp, setTimestamp] = useState('');
    const [results, setResults] = useState({});
    const [startOfDayTimestamp, setStartOfDayTimestamp] = useState('');

    const timeZones = ['UTC', 'America/New_York', 'Europe/London', 'Asia/Tokyo', 'Australia/Sydney'];

    const convertTimestamp = () => {
        const parsedTimestamp = parseInt(timestamp, 10);
        if (isNaN(parsedTimestamp)) {
            setResults({ error: 'Please enter a valid Unix timestamp.' });
            setStartOfDayTimestamp('');
            return;
        }

        const date = new Date(parsedTimestamp * 1000);
        const convertedResults = {};

        timeZones.forEach((tz) => {
            const options = { timeZone: tz, hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
            convertedResults[tz] = date.toLocaleString('en-US', options);
        });

        setResults(convertedResults);
        const startOfDay = new Date(date);
        startOfDay.setHours(0, 0, 0, 0);
        setStartOfDayTimestamp(Math.floor(startOfDay.getTime() / 1000));
    };

    return (
        <div className="tool">
            <h2>Unix Timestamp Converter</h2>
            <input
                type="text"
                value={timestamp}
                onChange={(e) => setTimestamp(e.target.value)}
                placeholder="Enter Unix timestamp"
            />
            <button onClick={convertTimestamp}>Convert</button>
            <div className="result">
                {results.error ? (
                    <p>{results.error}</p>
                ) : (
                    <>
                        {Object.keys(results).map((tz) => (
                            <p key={tz}><strong>{tz}:</strong> {results[tz]}</p>
                        ))}
                        <p><strong>Start of the day (00:00:00) timestamp:</strong> {startOfDayTimestamp}</p>
                    </>
                )}
            </div>
        </div>
    );
}

export default UnixTimestampConverter;

